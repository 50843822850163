import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { COLORS, BREAKPOINTS } from '../styles/constants';

import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 330,
  },
  heroContent: {
    marginTop: BREAKPOINTS.topbar,
  },
  link: {
    color: COLORS.pushback,
    textDecoration: 'none',
  },
}));

export default function Contact() {
  const classes = useStyles();
  return (
    <Layout>
      {/* Hero unit */}
      <Container
        classes={{
          root: classes.heroContent,
        }}
        maxWidth="sm"
        component="main"
        className={classes.heroContent}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Contact
        </Typography>
        <div>
          <h3>Feel free to contact us</h3>
          <address>
            <a className={classes.link} href="mailto: support@pushback.io">
              support@pushback.io
            </a>
            <br />
            <br />
            PlainIce LLC
            <br />
            285 N Main St
            <br />
            Suite 322
            <br />
            Kaysville, UT 84037 <br />
            USA <br />
          </address>
        </div>
      </Container>
    </Layout>
  );
}
